import http from "../http-common-documental";

class ProyectoService {
    getAll() {
        return http.get(`/proyecto`);
    }

    getById(id) {
        return http.get(`/proyecto/${id}`);
    }

    ingresar(data) {
        return http.post(`/proyecto`, data);
    }

    update(id, data) {
        return http.put(`/proyecto/${id}`, data);
    }

    eliminar(id) {
        return http.delete(`/proyecto/${id}`);
    }
}

export default new ProyectoService();