import http from "../http-common-documental";

class DocumentosService {
    getAll() {
        return http.get(`/documento`);
    }

    getById(id) {
        return http.get(`/documento/${id}`);
    }

    ingresar(data) {
        return http.post(`/documento`, data);
    }

    update(id, data) {
        return http.put(`/documento/${id}`, data);
    }

    eliminar(id) {
        return http.delete(`/documento/${id}`);
    }
}

export default new DocumentosService();