import React, { useEffect, useState } from 'react';
import { DotLoaderOverlay } from 'react-spinner-overlay';

/** Servicios */
import categoriaService from '../../services/categoria.service';
import documentosService from '../../services/documentos.service';

/** Material UI */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MUIDataTable from 'mui-datatables';

const Categorias = () => {
    const [docs, setDocs] = useState([]);
    const [nombre, setNombre] = useState('');
    const [upinsert, setUpinsert] = useState(true);
    const [loading, setLoading] = useState(false);
    const [categorias, setCategoria] = useState([]);

    useEffect(() => {
        retreiveCategora();
    }, []);

    async function retreiveCategora() {
        setLoading(true);
        try {
            let categorias = await categoriaService.getAll();
            if (categorias.data) {
                categorias.data.sort(function (a, b) {
                    if (a.nombre < b.nombre) {
                        return -1;
                    }
                    if (a.nombre > b.nombre) {
                        return 1;
                    }
                    return 0;
                });
                setCategoria(categorias.data);
                setLoading(false);
            }
        } catch(err) {
            console.error(err);
            setLoading(false);
        }
    }

async function retreiveDocs() {
    setLoading(true);
    try {
        let documentos = await documentosService.getAll();
        if (documentos.data) {
            documentos.data.sort(function (a, b) {
                if (a.nombre < b.nombre) {
                    return -1;
                }
                if (a.nombre > b.nombre) {
                    return 1;
                }
                return 0;
            });
            setDocs(documentos.data);
        }
    } catch (err) {
        console.error(err);
    }
    setLoading(false);
}

const columns = [
    { name: 'id', label: 'ID' },
    { name: 'nombre', label: 'Nombre' }
];

const options = {
    onRowsDelete: (e) => { console.log(e.data) },
    onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
        /* var d = docs.find(rowsSelected[0]);
        console.log(d); */
        //console.log(currentRowsSelected);
        var d = categorias[rowsSelected[0]];
        if (d) {
            setNombre(d);
            setUpinsert(false);
        } else {
            setNombre('');
            setUpinsert(true);
        }
    },
    onRowsDelete: (data) => {
        setLoading(true);
        var item = categorias[data.data[0].dataIndex]
        categoriaService.eliminar(item.id)
            .then(resp => {
                console.log(resp);
                retreiveCategora();
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
            });
    }
}

const handleNombre = (valor, name) => {
    setNombre({ ...nombre, [name]: valor });
}

const ingresar = () => {
    setLoading(true);
    categoriaService.ingresar({ nombre: nombre.nombre })
        .then(resp => {
            console.log(resp);
            retreiveCategora();
            setLoading(false);
        })
        .catch(err => {
            console.error(err);
            setLoading(false);
        });
}

const actualizar = () => {
    setLoading(true);
    categoriaService.update(nombre.id, { nombre: nombre.nombre })
        .then(resp => {
            console.log(resp);
            retreiveCategora();
            setLoading(false);
        })
        .catch(err => {
            console.error(err);
            setLoading(false);
        });
}

return (
    <Container>
        {loading ? (
            <DotLoaderOverlay size={28} message="Cargando..." color="#4F8BFA" />
        ) : ""}
        <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    label='Nombre'
                    id='nombre'
                    name='nombre'
                    value={nombre ? nombre.nombre : ""}
                    onChange={(e) => handleNombre(e.target.value.toUpperCase(), 'nombre')}
                />
                <Box
                    marginTop={3}
                >
                    {upinsert ? <Button variant='contained' onClick={ingresar}>AGREGAR</Button> : <Button variant='contained' color='secondary' onClick={actualizar}>ACTUALIZAR</Button>}
                </Box>

            </Grid>
            <Grid item xs={12} sm={6}>
                <MUIDataTable
                    data={categorias}
                    columns={columns}
                    options={options}
                />
            </Grid>
        </Grid>
    </Container>
)
}

export default Categorias;