import React, { useEffect, useState } from "react";

/** MUI */
import {
    Box,
    Button,
    Checkbox,
    Container,
    FormControlLabel,
    FormGroup,
    Grid,
    MenuItem,
    Stack,
    TextField
} from '@mui/material';

import MUIDataTable from 'mui-datatables';

/** Servicios */
import personasService from "../../services/personas.service";
import proyectoService from "../../services/proyecto.service";
import usuarioService from "../../services/usuario.service";

const Proyecto = () => {
    const [cc, setCc] = useState([]);
    const [proyectos, setProyectos] = useState([]);
    const [usuarios, setUsuarios] = useState([]);
    const [usr, setUsr] = useState('');
    const [proyectosUsuario, setProyectosUsuario] = useState([]);

    useEffect(() => {
        retreiveCC();
        retreiveUsuarios();
    }, []);

    function retreiveCC() {
        personasService.getCC()
            .then(resp => {
                console.log(resp.data);
                var _cc = resp.data.sort((a, b) => a.ctoDescripcion.localeCompare(b.ctoDescripcion));
                setCc(_cc);
            })
            .catch(err => {
                console.error(err);
            });
    };

    function retreiveUsuarios() {
        usuarioService.getAll()
            .then(resp => {
                console.log(resp.data);
                setUsuarios(resp.data);
                setProyectosUsuario(resp.data.proyectos);
            })
            .catch(err => {
                console.error(err);
            });
    }

    const handleProyecto = (e) => {
        console.log("E", e);
        if (e.target.checked) {
            proyectos.push({ nombre: e.target.value });
            setProyectos([...proyectos]);
        } else {
            var inx = proyectos.findIndex(proy => proy.nombre === e.target.value);
            if (inx >= 0) {
                proyectos.splice(inx, 1);
                setProyectos([...proyectos]);
            }
        }
        console.log("Proyectos", proyectos);
    };

    const ingresar = () => {
        for (var p in proyectos) {
            console.log({
                usuario: usr,
                proyecto: proyectos[p]
            });
            const data = {nombre: proyectos[p].nombre, usuarioId: usr.id};
            proyectoService.ingresar(data)
            .then(resp=>{
                console.log(resp.data);
                retreiveUsuarios();
            })
            .catch(err=>{
                console.error(err);
            });
        }
    };

    const columns = [
        {
            name: 'nombre', label:'Nombre'
        }
    ];

    const handleUsuario = (value) => {
        console.log(value);
        usuarioService.getByMail({mail: value})
        .then(resp=>{
            console.log(resp.data);
            setUsr(resp.data);
            setProyectosUsuario([...resp.data.proyectos]);
        })
        .catch(err=>{
            console.error(err);
        });
    };

    const options = {
        onRowsDelete:(e)=>{deleteRow(e.data)},
        selectableRows:'single',
    };

    const deleteRow = (arr_data) => {
        for(var i in arr_data) {
            var index = arr_data[i].index;
            const id_proy = proyectosUsuario[index].id;
            proyectoService.eliminar(id_proy)
            .then(resp=>{
                console.log(resp.data);
            })
            .catch(err=>{
                console.error(err);
            });
        };
    }
 
    return (
        <Container>
            <Box sx={{
                margin: 3
            }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                        <Stack direction="column" spacing={2}>
                            <Button variant="contained" onClick={ingresar}>Ingresar</Button>
                            <TextField
                                name="usuario"
                                label="Usuario"
                                fullWidth
                                select
                                onChange={(e) => handleUsuario(e.target.value)}
                                value={usr ? usr?.mail : ""}
                            >
                                {usuarios.map(u => (
                                    <MenuItem key={u.mail} value={u.mail}>{u.mail}</MenuItem>
                                ))}
                            </TextField>
                            <FormGroup>
                                {cc.map(c => (
                                    <FormControlLabel key={c.ctoDescripcion} control={<Checkbox value={c.ctoDescripcion} onChange={(e) => handleProyecto(e)} />} label={c.ctoDescripcion} />
                                ))}
                            </FormGroup>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <MUIDataTable
                            data={proyectosUsuario}
                            columns={columns}
                            options={options}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}

export default Proyecto;