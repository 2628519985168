import http_persona from '../http-common-personas';

class PersonaService {
    getPorRut(rut) {
        return http_persona.get(`/personas/${rut}`);
    }

    getAll() {
        return http_persona.get('/personas');
    }

    getCC() {
        return http_persona.get('/personas/cc/get');
    }
}

export default new PersonaService();