import React, { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
/** Servicios */
import usuarioService from "../services/usuario.service";

/** Componentes */

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("site"))?.user || null);
    const [token, setToken] = useState(JSON.parse(localStorage.getItem("site"))?.token || "");
    const navigate = useNavigate();

    const loginAction = async (username, password) => {
        return await new Promise(async (resolve, reject) => {
            try {
                console.log("User Name", username);
                const response = await usuarioService.signin({mail: username, password: password});
                if (response.data) {
                    setUser(response.data.user);
                    setToken(response.data.accessToken);
                    var data = { user: response.data.user, token: response.data.accessToken }
                    localStorage.setItem("site", JSON.stringify(data));
                    resolve(data);
                    navigate("/upload");
                    return;
                }
            } catch (error) {
                reject(error);
            }
        });
        /* try {
            const response = await usuarioService.signin(data);
            if (response.data) {
                setUser(response.data.user);
                setToken(response.data.accessToken);
                var data = { user: response.data.user, token: response.data.accessToken }
                localStorage.setItem("site", JSON.stringify(data));
                navigate("/upload");
                return;
            }
        } catch (err) {
            console.error(err);
            return new Error("error_autentificar");
        } */
    };

    const logOut = () => {
        setUser(null);
        setToken("");
        localStorage.removeItem("site");
        navigate("/");
    };

    return (
        <AuthContext.Provider value={{ token, user, loginAction, logOut }}>
            {children}
        </AuthContext.Provider>
    );
}

export default AuthProvider;

export const useAuth = () => {
    return useContext(AuthContext);
};