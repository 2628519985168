import http from "../http-common-documental";

class UsuarioService {
    getAll() {
        return http.get(`/usuario`);
    }

    getById(id) {
        return http.get(`/usuario/${id}`);
    }

    ingresar(data) {
        return http.post(`/usuario`, data);
    }

    update(id, data) {
        return http.put(`/usuario/${id}`, data);
    }

    eliminar(id) {
        return http.delete(`/usuario/${id}`);
    }

    signin(data) {
        return http.post('/usuario/signin', data);
    }

    getByMail(data) {
        return http.post('/usuario/mail', data);
    }
}

export default new UsuarioService();