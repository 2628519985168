import http from "../http-common-documental";

class CategoriaService {
    getAll() {
        return http.get(`/maestrocategoria`);
    }

    getById(id) {
        return http.get(`/maestrocategoria/${id}`);
    }

    ingresar(data) {
        return http.post(`/maestrocategoria`, data);
    }

    update(id, data) {
        return http.put(`/maestrocategoria/${id}`, data);
    }

    eliminar(id) {
        return http.delete(`/maestrocategoria/${id}`);
    }
}

export default new CategoriaService();