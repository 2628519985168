import { ConfirmProvider } from "material-ui-confirm";
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Login from './components/login.component';
import Proyecto from './components/pages/proyectos.page';
import SubirArchivo from './components/subirarchivo.component';

function App() {
  return (
    <ConfirmProvider>
      <div>
        <Routes>
          <Route path='/' element={<Login />} />
          <Route path='/proyecto' element={<Proyecto />} />
          <Route path='/upload/*' element={<SubirArchivo />} />
        </Routes>
      </div>
    </ConfirmProvider>
  );
}

export default App;
