import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { saveAs } from 'save-as';

import moment from 'moment';
import { SnackbarProvider, useSnackbar } from 'notistack';
import S3 from 'react-aws-s3';
import { DotLoaderOverlay } from 'react-spinner-overlay';
import AutoComplete from '../autocomplete.componet';

/** Servicios */
import categoriaService from '../../services/categoria.service';
import documentalService from '../../services/documental.service';
import documentosService from '../../services/documentos.service';
import personasService from '../../services/personas.service';

/** Material UI */
import AttachFileIcon from '@mui/icons-material/AttachFile';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CheckIcon from '@mui/icons-material/Check';
import CircleIcon from '@mui/icons-material/Circle';
import DeleteIcon from '@mui/icons-material/Delete';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { InputLabel, MenuItem, Select } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Autocomplete from '@mui/material/Autocomplete';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Checkbox from '@mui/material/Checkbox';
import { red } from '@mui/material/colors';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import esLocale from 'date-fns/locale/es';
import { useConfirm } from "material-ui-confirm";
import { useAuth } from '../authprovide.component';

import { useNavigate } from "react-router-dom";

/** Estilos CSS MUI */
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const style_add_file = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const S3_BUCKET = 'docrecluta';
const REGION = 'sa-east-1';

function base64toBlob(base64Data) {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        const begin = sliceIndex * sliceSize;
        const end = Math.min(begin + sliceSize, bytesLength);

        const bytes = new Array(end - begin);
        for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
            bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: "application/pdf" });
}

function addDays(date, days) {
    date.setDate(date.getDate() + days);
    return date;
}

function semaforoFecha(fecha) {
    var result = "";
    //A 30 días
    var menos_30 = addDays(new Date(fecha), -30);
    var mas_30 = addDays(new Date(), 30);
    var mas_60 = addDays(new Date(), 60);
    var f_hoy = new Date();
    var f_original = new Date(fecha);

    if (f_hoy >= f_original) {
        return "Rojo";
    } else if (f_original >= f_hoy && f_original <= mas_30) {
        return "Naranja";
    } else if (f_original >= f_hoy && f_original <= mas_60) {
        return "Amarillo";
    } else if (f_original >= f_hoy && f_original >= mas_60) {
        return "Verde";
    }
}

function colorSemaforo(resultado, fecha) {
    if (resultado === "Rojo") {
        return (
            <Box sx={{
                backgroundColor: "red",
                color: "black"
            }}>
                {moment(fecha).format("DD-MM-YYYY")}
            </Box>
        )
    }
}

const ListarArchivos = (props) => {
    const [personas, setPersonas] = useState([]);
    const [persona, setPersona] = useState('');
    const [documentos, setDocumentos] = useState([]);
    const [open, setOpen] = useState(false);
    const [documento, setDocumento] = useState('');
    const [indefinido, setIndefinido] = useState(false);
    const [loading, setLoading] = useState(false);
    const [idx, setIdx] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const confirm = useConfirm();
    const navigate = useNavigate();
    const [name, setName] = useState('');
    //const [user, loadingUser, error] = useAuthState(auth);
    const [user, setUser] = useState('');
    const authp = useAuth();
    const [categorias, setCategorias] = useState([]);
    const [value, setValue] = React.useState(4);
    const [enableBtn, setEnableBtn] = useState(true);
    const [modalIngreso, setModalIngreso] = useState(false);
    const [ingreso, setIngreso] = useState(true);
    const [gestion, setGestion] = useState('');
    const [maetroDocumentos, setMaestroDocumentos] = useState([]);
    const [docsAuto, setDocsAuto] = useState([]);
    const [percentage, setPercentage] = useState(0);

    useEffect(() => {
        if (authp) {
            retreivePersonas();
            setGestion({ ...gestion, ['author']: authp.user.nombre });
            //retreiveCategorias();
        }
        /* if (loadingUser) return;
        if (!user) return navigate("/"); */
        setName(authp.user);
        //setUser(authp.user);
    }, [authp]);

    const closeModal = () => setOpen(false);
    const closeModalIngreso = () => setModalIngreso(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    async function retreiveMaestroDocumentos() {
        setLoading(true);
        var dc_temp = [];
        documentosService.getAll()
            .then(data => {
                data.data.sort(function (a, b) {
                    if (a.nombre < b.nombre) {
                        return -1;
                    }
                    if (a.nombre > b.nombre) {
                        return 1;
                    }
                    return 0;
                });
                for (var i in data.data) {
                    var p = { label: data.data[i].nombre };
                    dc_temp.push(p);
                }
                setMaestroDocumentos(data.data);
                setDocsAuto(dc_temp);
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
            });
    }

    const fetchUserName = async () => {
        setName(authp.user);
        setUser(authp.user);
        /* try {
            const q = query(collection(db, "users"), where("uid", "==", user?.uid));
            const doc = await getDocs(q);
            const data = doc.docs[0].data();
            setName(data);
        } catch (err) {
            console.error(err);
            alert("An error occured while fetching user data");
        } */
    };

    async function retreiveCategorias() {
        setLoading(true);
        categoriaService.getAll()
            .then(resp => {
                setCategorias(resp.data);
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
            });
    }

    async function retreivePersonas() {
        setLoading(true);
        personasService.getAll()
            .then(data => {
                setLoading(true);
                var persons = data.data;
                var arr = [];
                if (authp.user.proyectos && authp.user.proyectos.length > 0) {
                    var proyectos = authp.user.proyectos;
                    for (var p in proyectos) {
                        var pers = persons.filter(v => v.ctoDescripcion == proyectos[p].nombre);
                        arr = arr.concat(pers);
                    }
                }
                setPersonas(arr);
                retreiveCategorias();
                retreiveMaestroDocumentos();
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
            });
    }

    const buscarPersona = () => {
        setLoading(true);
        const valor = document.getElementById("autocompletar");
        const r = valor.value.split(' - ');
        const rut2 = r[1];
        var per = personas.find(v => v.Rut === rut2);
        setPersona(per);
        var rut_ = rut2.replaceAll('.', '');
        setDocumentos([]);
        documentalService.getPorRut(rut_)
            .then(data => {
                let docs = data.data;
                if (user.email === "hsec@ecora.cl") {
                    let docs_filter = docs.filter(item => item.name.toUpperCase().indexOf("ODI") > -1 ||
                        item.name.toUpperCase().indexOf("RIOHS") > -1 || item.name.toUpperCase().indexOf("CONTRATO") > -1 ||
                        item.name.toUpperCase().indexOf("EPP") > -1);
                    setDocumentos(docs_filter);
                    setLoading(false);
                } else {
                    setDocumentos(data.data);
                    setIngreso(false);
                    setLoading(false);
                }

            })
            .catch(err => {
                console.error(err);
                setLoading(false);
            });
    }

    const abriRuta = (link, pdf64) => {
        if (link !== null) {
            window.open(link);
        } else {
            var blob = base64toBlob(pdf64);
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob, "pdfBase64.pdf");
            } else {
                const blobUrl = URL.createObjectURL(blob);
                window.open(blobUrl);
            }
        }

    }

    const openModalDoc = (id, index) => {
        documentalService.getById(id)
            .then(data => {
                setDocumento(data.data);
                setIdx(index);
                setOpen(true);
            })
            .catch(err => {
                console.error(err);
            });
    }

    function retreiveDocs(id) {
        documentalService.getById(id)
            .then(data => {
                setDocumento(data.data);
            })
            .catch(err => {
                console.error(err);
            });
    }

    const onChangeIndefinido = (e) => {
        if (e.target.checked) {
            setIndefinido(true);
            setDocumento({ ...documento, ['valid_through']: "2050-12-31" });
        } else {
            setIndefinido(false);
        }
    }

    const onChangeNa = (e) => {
        if (e.target.checked) {
            setDocumento({ ...documento, ['no_aplica']: "NA" });
        } else {
            setDocumento({ ...documento, ['no_aplica']: "" });
        }
    }

    const handleDocumento = (valor, nombre) => {
        setDocumento({ ...documento, [nombre]: valor });
    }

    const actualizarDocumento = () => {
        setOpen(false);
        setLoading(true);
        if (documento.archivo) {
            const config = {
                bucketName: 'docrecluta',
                dirName: documento.code,
                acl: 'public-read',
                //dirName: 'photos', /* optional */
                region: 'sa-east-1',
                accessKeyId: 'AKIAV5REHHADUKEJVTJO',
                secretAccessKey: '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',
            }
            window.Buffer = window.Buffer || require("buffer").Buffer;
            const ReactS3Client = new S3(config);
            var extension = documento.archivo[0].name.split('.').pop();
            ReactS3Client.uploadFile(documento.archivo[0], documento.name + "." + extension)
                .then(data => {
                    //Actualizamos la info.
                    documento.ruta = data.location;
                    documentalService.updateGD(documento.id, documento)
                        .then(resp => {
                            retreivePersonas();
                            retreiveDocs(documento.id);
                            documentos[idx].ruta = documento.ruta;
                            setLoading(false);
                            enqueueSnackbar("Archivo actualizado correctamente!!!", { variant: 'success' });
                        })
                        .catch(err => {
                            console.error(err);
                            setLoading(false);
                        });
                })
                .catch(err => {
                    console.error(err);
                    setLoading(false);
                });
        } else {
            //Actualizamos la info.
            documentalService.updateGD(documento.id, documento)
                .then(resp => {
                    documentos[idx] = documento;
                    buscarPersona();
                    setLoading(false);
                    enqueueSnackbar("Archivo actualizado correctamente!!!", { variant: 'success' });
                })
                .catch(err => {
                    console.error(err);
                    setLoading(false);
                });
        }
    }

    const eliminarGD = (id) => {
        confirm({ description: `Esta seguro de eliminar el documento?`, title: "Eliminar Documento", confirmationText: "SI", cancellationText: "NO" })
            .then(() => {
                setLoading(true);
                documentalService.deleteGD(id)
                    .then(resp => {
                        buscarPersona();
                        setLoading(false);
                        enqueueSnackbar("Documento eliminado correctamente!!", { variant: 'success' });
                    })
                    .catch(err => {
                        console.error(err);
                        setLoading(false);
                    });
            });
    };

    const handleGestion = (valor, nombre) => {
        console.log("Nombre Handle", valor);
        setGestion({ ...gestion, [nombre]: valor });
    };

    const ingresarGD = () => {
        if (!gestion.name) {
            enqueueSnackbar("Falta ingresar el Nombre", { variant: 'info' });
            return;
        }
        if (documento.no_aplica == "NA") {
            if (!gestion.valid_from) {
                enqueueSnackbar("Falta ingresar la fecha de inicio", { variant: 'info' });
                return;
            }
            if (!gestion.valid_through) {
                enqueueSnackbar("Falta ingresar la fecha de termino", { variant: 'info' });
                return;
            }
        }

        if (!gestion.author) {
            enqueueSnackbar("Falta ingresar el Autor", { variant: 'info' });
            return;
        }
        if (!gestion.archivo) {
            enqueueSnackbar("Falta cargar el Archivo", { variant: 'info' });
            return;
        }

        if (!gestion.categoria) {
            enqueueSnackbar("Falta ingresar la categoria", { variant: 'info' });
            return;
        }
        setLoading(true);
        var code = persona.Rut.replaceAll('.', '');
        const dataGestion = {
            valid_from: gestion.valid_from,
            valid_through: gestion.valid_through,
            author: gestion.author,
            code: code,
            name: gestion.name,
            categoria: gestion.categoria,
            no_aplica: documento ? documento.no_aplica : "",
        }
        //Ingresamos los datos
        // TO DO

        if (gestion.archivo) {
            const config = {
                bucketName: 'docrecluta',
                dirName: code,
                acl: 'public-read',
                //dirName: 'photos', /* optional */
                region: 'sa-east-1',
                accessKeyId: 'AKIAV5REHHADUKEJVTJO',
                secretAccessKey: '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',
            }
            window.Buffer = window.Buffer || require("buffer").Buffer;
            const ReactS3Client = new S3(config);
            var extension = gestion.archivo[0].name.split('.').pop();
            ReactS3Client.uploadFile(gestion.archivo[0], gestion.name + "." + extension)
                .then(data => {
                    //Actualizamos la info.
                    dataGestion.ruta = data.location;
                    documentalService.ingresarGD(dataGestion)
                        .then(resp => {
                            //retreivePersonas();
                            setModalIngreso(false);
                            buscarPersona();
                            retreiveDocs();
                            setLoading(false);
                            enqueueSnackbar("Archivo cargado correctamente!!!", { variant: 'success' });
                        })
                        .catch(err => {
                            console.error(err);
                            setLoading(false);
                        });
                })
                .catch(err => {
                    console.error(err);
                    setLoading(false);
                });
        }
    };

    function urlToPromise(url) {
        setLoading(true);
        return new Promise(function (resolve, reject) {
            JSZipUtils.getBinaryContent(url, function (err, data) {
                if (err) {
                    reject(err);
                    setLoading(false);
                } else {
                    resolve(data);
                    setLoading(false);
                }
            });
        });
    }

    const descargarZip = (rut_persona) => {
        setLoading(true);
        var rut_ = rut_persona.replaceAll(".", "");
        var zip = new JSZip();
        const fold = zip.folder(rut_);
        documentos.map(d => {
            if (d.ruta !== null) {
                var filename = d.ruta.split('/').pop();
                var extension = filename.split('.').pop();
                fold.file(filename, urlToPromise(d.ruta), { binary: true });
            } else if(d.pdf64 !== null) {
                var filename = d.name + ".pdf";
                var blob = base64toBlob(d.pdf64);
                const blobUrl = URL.createObjectURL(blob);
                fold.file(filename, blob, { binary: true });
            }
        });
        zip.generateAsync({ type: 'blob' }, function updateCallback(metadata) {
            setPercentage(metadata.percent.toFixed(2));
            /* if (metadata.currentFile) {
                console.log("current file = " + metadata.currentFile);
            } */
        })
            .then(function (content) {
                saveAs(content, rut_ + ".zip");
                setPercentage(0);
            });
        setLoading(false);
    }

    return (
        <Container>
            {loading ? (
                <DotLoaderOverlay size={28} message="Cargando..." color="#4F8BFA" />
            ) : ""}
            <Box>
                <FormControl fullWidth>
                    <Stack direction="column" spacing={2} maxWidth>
                        <AutoComplete suggestions={personas.map(({ perApMaterno, perNombre1, perApPaterno, Rut }) => perNombre1 + " " + perApPaterno + " " + perApMaterno + " - " + Rut)} />
                        <Button variant='contained' onClick={buscarPersona}>Buscar</Button>
                    </Stack>
                </FormControl>
            </Box>
            {persona ? (
                <>
                    <Box
                        sx={{
                            margin: 3,
                            padding: 2,
                            boxShadow: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex'
                        }}
                    >
                        <Card sx={{ maxWidth: 345 }}>
                            <CardHeader
                                avatar={
                                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                                        {persona.perNombre1.charAt(0).toUpperCase()}
                                    </Avatar>
                                }
                                /* action={
                                    <IconButton aria-label="settings">
                                        <MoreVertIcon />
                                    </IconButton>
                                } */
                                title={persona.perNombre1 + " " + persona.perApPaterno + " " + persona.perApMaterno}
                                subheader={persona.ctoDescripcion}
                            />
                            <CardContent>
                                <Typography variant="body2" color="text.secondary">
                                    {persona.recDescripcion}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Box>
                    <Box sx={{
                        margin: 3,
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                        display: "flex"
                    }}>
                        <Button variant='contained' color='info' onClick={() => setModalIngreso(true)}>Agregar Archivo</Button>
                    </Box>
                    <Box
                        sx={{
                            margin: 3,
                            //padding: 2,
                            boxShadow: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex'
                        }}
                    >
                        <Stack spacing={2} direction="row">
                            <CircleIcon sx={{ color: "green" }} />
                            <p>Documento al día</p>
                            <CircleIcon sx={{ color: "yellow" }} />
                            <p>Documento vencerá dentro de los 60 días</p>
                            <CircleIcon sx={{ color: "orange" }} />
                            <p>Documento vencerá dentro de los 30 días</p>
                            <CircleIcon sx={{ color: "red" }} />
                            <p>Documento vencido</p>
                        </Stack>
                        {/* <Table sx={{ minWidth: 400 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Simbolo</TableCell>
                                    <TableCell>Signficado</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell><CircleIcon sx={{ color: "green" }} /></TableCell>
                                    <TableCell>Documento al día</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><CircleIcon sx={{ color: "yellow" }} /></TableCell>
                                    <TableCell>Documento vencerá dentro de los 60 días</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><CircleIcon sx={{ color: "orange" }} /></TableCell>
                                    <TableCell>Documento vencerá dentro de los 30 días</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><CircleIcon sx={{ color: "red" }} /></TableCell>
                                    <TableCell>Documento vencido</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table> */}
                    </Box>
                </>
            ) : ""}
            {/* <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Documento</TableCell>
                            <TableCell align="right">Fecha Inicio</TableCell>
                            <TableCell align="right">Fecha Termino</TableCell>
                            <TableCell align="right">Status</TableCell>
                            <TableCell align="right">Doc</TableCell>
                            {name.perfiles && name.perfiles.some(v => v.nombre === "rrhh") ? (
                                <>
                                    <TableCell align="right">Activar Alarma</TableCell>
                                    <TableCell align="right">Editar</TableCell>
                                </>
                            ) : (
                                name.perfiles && name.perfiles.some(v => v.nombre === "administrador") ? (
                                    <>
                                        <TableCell align="right">Activar Alarma</TableCell>
                                        <TableCell align="right">Categoria</TableCell>
                                        <TableCell align="right">Editar</TableCell>
                                        <TableCell align="right">Eliminar</TableCell>
                                    </>
                                ) : ""
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {console.log("Usuario", name.perfiles ? name.perfiles.some(v => v.nombre === "rrhh") : "")}
                        {documentos.map((row, index) => (
                            <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell align="right">{moment(row.valid_from).format("DD-MM-YYYY")}</TableCell>
                                <TableCell align="right">{moment(row.valid_through).format("DD-MM-YYYY")}</TableCell>
                                <TableCell align='right'>{semaforoFecha(row.valid_through) === "Verde" ? <CircleIcon sx={{ color: "green" }} /> : semaforoFecha(row.valid_through) == "Rojo" ? <CircleIcon sx={{ color: "red" }} /> : semaforoFecha(row.valid_through) == "Naranja" ? <CircleIcon sx={{ color: "orange" }} /> : semaforoFecha(row.valid_through) == "Amarillo" ? <CircleIcon sx={{ color: "yellow" }} /> : ""}</TableCell>
                                <TableCell align="right">{row.ruta || row.pdf64 ? <IconButton onClick={() => abriRuta(row.ruta, row.pdf64)} color='primary'><PictureAsPdfIcon /></IconButton> : <IconButton color='error'><PictureAsPdfIcon /></IconButton>}</TableCell>
                                {name.perfiles && name.perfiles.some(v => v.nombre === "rrhh") ? (
                                    <>
                                        <TableCell align="right">{row.no_aplica === "NA" ? <CheckIcon color='success' /> : ""}</TableCell>
                                        <TableCell align="right"><IconButton onClick={() => openModalDoc(row.id, index)} color='primary'><BorderColorIcon /></IconButton></TableCell>
                                    </>
                                ) : (name.perfiles && name.perfiles.some(v => v.nombre === "administrador") ? (
                                    <>
                                        <TableCell align="right">{row.no_aplica === "NA" ? <CheckIcon color='success' /> : ""}</TableCell>
                                        <TableCell align="right">{row.categoria}</TableCell>
                                        <TableCell align="right"><IconButton onClick={() => openModalDoc(row.id, index)} color='primary'><BorderColorIcon /></IconButton></TableCell>
                                        <TableCell align="right"><IconButton onClick={() => eliminarGD(row.id)} color='primary'><DeleteIcon /></IconButton></TableCell>
                                    </>
                                ) : ""
                                )}

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer> */}
            <Modal
                open={open}
                onClose={closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <AppBar>
                        <Typography id="modal-modal-title" variant="h6" component="h2" align="center" style={{ marginBottom: 10 }}>
                            Editar Documento
                        </Typography>
                    </AppBar>
                    <Box
                        sx={{
                            marginTop: 3
                        }}
                    >
                        <Typography variant='h6' align='center'>
                            {documento.name}
                        </Typography>
                    </Box>
                    <Grid container spacing={2} mt={3}>
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                                <InputLabel id="categoria">Categoria</InputLabel>
                                <Select
                                    labelId="categoria"
                                    id="categoria"
                                    label="Categoria"
                                    onChange={(e) => handleDocumento(e.target.value, 'categoria')}
                                >
                                    {categorias.map(md => (
                                        <MenuItem key={md.nombre} value={md.nombre}>{md.nombre}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                                <DatePicker
                                    label="Inicio de Vigencia"
                                    value={documento.valid_from ? documento.valid_from : ""}
                                    onChange={(newValue) => handleDocumento(newValue, 'valid_from')}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                                <DatePicker
                                    label="Termino de Vigencia"
                                    value={documento.valid_through ? documento.valid_through : ""}
                                    disabled={indefinido}
                                    onChange={(newValue) => handleDocumento(newValue, 'valid_through')}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Stack direction="column">
                                {/* <FormControlLabel control={<Checkbox onChange={(e) => onChangeIndefinido(e)} />} label="Indefinido" /> */}
                                <FormControlLabel control={<Checkbox onChange={(e) => onChangeNa(e)} checked={documento.no_aplica == "NA" ? true : false} />} label="Activar Alarma" />
                            </Stack>

                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Button variant='contained' component='label' startIcon={<AttachFileIcon />}>
                                Cargar
                                <input hidden type="file" onChange={(e) => handleDocumento(e.target.files, 'archivo')} />
                            </Button>
                        </Grid>
                    </Grid>
                    <Box
                        sx={{
                            alignItems: 'end',
                            display: 'flex',
                            marginTop: 2,
                            justifyContent: 'right'
                        }}
                    >
                        <Button variant='contained' color='success' onClick={actualizarDocumento}>Actualizar</Button>
                    </Box>
                </Box>
            </Modal>
            {persona ? (
                <Box sx={{
                    margin: 3,
                    justifyContent: "end",
                    alignContent: "end",
                    alignItems: "end",
                    display: "flex"
                }}>
                    <Button variant='contained' color='secondary' onClick={() => descargarZip(persona.Rut)}>Descargar Archivos</Button>
                </Box>
            ) : ""}
            {percentage > 0 ? (
                    <Box sx={{ width: '100%', margin: 3 }}>
                        <Typography variant='subtitle2'>
                            Descargando Archivos ...
                        </Typography>
                        <LinearProgressWithLabel value={percentage} />
                    </Box>
            ) : ""}
            <Box sx={{ width: '100%', margin: 3 }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
                        <Tab label="PERSONALES" {...a11yProps(0)} />
                        <Tab label="EXAM. Y CURSOS" {...a11yProps(1)} />
                        <Tab label="CONTRATOS" {...a11yProps(2)} />
                        <Tab label="CERTIFICACIONES" {...a11yProps(3)} />
                        <Tab label="OTROS" {...a11yProps(4)} />
                        <Tab label="HISORICOS" {...a11yProps(5)} />
                    </Tabs>
                </Box>
                {/** PERSNALES */}
                <CustomTabPanel value={value} index={0}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Documento</TableCell>
                                    <TableCell align="right">Fecha Inicio</TableCell>
                                    <TableCell align="right">Fecha Termino</TableCell>
                                    <TableCell align="right">Status</TableCell>
                                    <TableCell align="right">Doc</TableCell>
                                    {name.perfiles && name.perfiles.some(v => v.nombre === "rrhh") ? (
                                        <>
                                            <TableCell align="right">Activar Alarma</TableCell>
                                            <TableCell align="right">Editar</TableCell>
                                        </>
                                    ) : (
                                        name.perfiles && name.perfiles.some(v => v.nombre === "administrador") ? (
                                            <>
                                                <TableCell align="right">Activar Alarma</TableCell>
                                                <TableCell align="right">Categoria</TableCell>
                                                <TableCell align="right">Editar</TableCell>
                                                <TableCell align="right">Eliminar</TableCell>
                                            </>
                                        ) : ""
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {documentos.filter(v => v.categoria === "PERSONALES").map((row, index) => (
                                    <TableRow
                                        key={row.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="right">{moment(row.valid_from).format("DD-MM-YYYY")}</TableCell>
                                        <TableCell align="right">{moment(row.valid_through).format("DD-MM-YYYY")}</TableCell>
                                        <TableCell align='right'>{semaforoFecha(row.valid_through) === "Verde" ? <CircleIcon sx={{ color: "green" }} /> : semaforoFecha(row.valid_through) == "Rojo" ? <CircleIcon sx={{ color: "red" }} /> : semaforoFecha(row.valid_through) == "Naranja" ? <CircleIcon sx={{ color: "orange" }} /> : semaforoFecha(row.valid_through) == "Amarillo" ? <CircleIcon sx={{ color: "yellow" }} /> : ""}</TableCell>
                                        <TableCell align="right">{row.ruta || row.pdf64 ? <IconButton onClick={() => abriRuta(row.ruta, row.pdf64)} color='primary'><PictureAsPdfIcon /></IconButton> : <IconButton color='error'><PictureAsPdfIcon /></IconButton>}</TableCell>
                                        {name.perfiles && name.perfiles.some(v => v.nombre === "rrhh") ? (
                                            <>
                                                <TableCell align="right">{row.no_aplica === "NA" ? <CheckIcon color='success' /> : ""}</TableCell>
                                                <TableCell align="right"><IconButton onClick={() => openModalDoc(row.id, index)} color='primary'><BorderColorIcon /></IconButton></TableCell>
                                            </>
                                        ) : (name.perfiles && name.perfiles.some(v => v.nombre === "administrador") ? (
                                            <>
                                                <TableCell align="right">{row.no_aplica === "NA" ? <CheckIcon color='success' /> : ""}</TableCell>
                                                <TableCell align="right">{row.categoria}</TableCell>
                                                <TableCell align="right"><IconButton onClick={() => openModalDoc(row.id, index)} color='primary'><BorderColorIcon /></IconButton></TableCell>
                                                <TableCell align="right"><IconButton onClick={() => eliminarGD(row.id)} color='primary'><DeleteIcon /></IconButton></TableCell>
                                            </>
                                        ) : ""
                                        )}

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CustomTabPanel>
                {/** EXAM Y CURSOS */}
                <CustomTabPanel value={value} index={1}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Documento</TableCell>
                                    <TableCell align="right">Fecha Inicio</TableCell>
                                    <TableCell align="right">Fecha Termino</TableCell>
                                    <TableCell align="right">Status</TableCell>
                                    <TableCell align="right">Doc</TableCell>
                                    {name.perfiles && name.perfiles.some(v => v.nombre === "rrhh") ? (
                                        <>
                                            <TableCell align="right">Activar Alarma</TableCell>
                                            <TableCell align="right">Editar</TableCell>
                                        </>
                                    ) : (
                                        name.perfiles && name.perfiles.some(v => v.nombre === "administrador") ? (
                                            <>
                                                <TableCell align="right">Activar Alarma</TableCell>
                                                <TableCell align="right">Categoria</TableCell>
                                                <TableCell align="right">Editar</TableCell>
                                                <TableCell align="right">Eliminar</TableCell>
                                            </>
                                        ) : ""
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {documentos.filter(v => v.categoria === "EXAMENES Y CURSOS").map((row, index) => (
                                    <TableRow
                                        key={row.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="right">{moment(row.valid_from).format("DD-MM-YYYY")}</TableCell>
                                        <TableCell align="right">{moment(row.valid_through).format("DD-MM-YYYY")}</TableCell>
                                        <TableCell align='right'>{semaforoFecha(row.valid_through) === "Verde" ? <CircleIcon sx={{ color: "green" }} /> : semaforoFecha(row.valid_through) == "Rojo" ? <CircleIcon sx={{ color: "red" }} /> : semaforoFecha(row.valid_through) == "Naranja" ? <CircleIcon sx={{ color: "orange" }} /> : semaforoFecha(row.valid_through) == "Amarillo" ? <CircleIcon sx={{ color: "yellow" }} /> : ""}</TableCell>
                                        <TableCell align="right">{row.ruta || row.pdf64 ? <IconButton onClick={() => abriRuta(row.ruta, row.pdf64)} color='primary'><PictureAsPdfIcon /></IconButton> : <IconButton color='error'><PictureAsPdfIcon /></IconButton>}</TableCell>
                                        {name.perfiles && name.perfiles.some(v => v.nombre === "rrhh") ? (
                                            <>
                                                <TableCell align="right">{row.no_aplica === "NA" ? <CheckIcon color='success' /> : ""}</TableCell>
                                                <TableCell align="right"><IconButton onClick={() => openModalDoc(row.id, index)} color='primary'><BorderColorIcon /></IconButton></TableCell>
                                            </>
                                        ) : (name.perfiles && name.perfiles.some(v => v.nombre === "administrador") ? (
                                            <>
                                                <TableCell align="right">{row.no_aplica === "NA" ? <CheckIcon color='success' /> : ""}</TableCell>
                                                <TableCell align="right">{row.categoria}</TableCell>
                                                <TableCell align="right"><IconButton onClick={() => openModalDoc(row.id, index)} color='primary'><BorderColorIcon /></IconButton></TableCell>
                                                <TableCell align="right"><IconButton onClick={() => eliminarGD(row.id)} color='primary'><DeleteIcon /></IconButton></TableCell>
                                            </>
                                        ) : ""
                                        )}

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CustomTabPanel>
                {/** CONTRATOS */}
                <CustomTabPanel value={value} index={2}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Documento</TableCell>
                                    <TableCell align="right">Fecha Inicio</TableCell>
                                    <TableCell align="right">Fecha Termino</TableCell>
                                    <TableCell align="right">Status</TableCell>
                                    <TableCell align="right">Doc</TableCell>
                                    {name.perfiles && name.perfiles.some(v => v.nombre === "rrhh") ? (
                                        <>
                                            <TableCell align="right">Activar Alarma</TableCell>
                                            <TableCell align="right">Editar</TableCell>
                                        </>
                                    ) : (
                                        name.perfiles && name.perfiles.some(v => v.nombre === "administrador") ? (
                                            <>
                                                <TableCell align="right">Activar Alarma</TableCell>
                                                <TableCell align="right">Categoria</TableCell>
                                                <TableCell align="right">Editar</TableCell>
                                                <TableCell align="right">Eliminar</TableCell>
                                            </>
                                        ) : ""
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {documentos.filter(v => v.categoria === "CONTRATOS").map((row, index) => (
                                    <TableRow
                                        key={row.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="right">{moment(row.valid_from).format("DD-MM-YYYY")}</TableCell>
                                        <TableCell align="right">{moment(row.valid_through).format("DD-MM-YYYY")}</TableCell>
                                        <TableCell align='right'>{semaforoFecha(row.valid_through) === "Verde" ? <CircleIcon sx={{ color: "green" }} /> : semaforoFecha(row.valid_through) == "Rojo" ? <CircleIcon sx={{ color: "red" }} /> : semaforoFecha(row.valid_through) == "Naranja" ? <CircleIcon sx={{ color: "orange" }} /> : semaforoFecha(row.valid_through) == "Amarillo" ? <CircleIcon sx={{ color: "yellow" }} /> : ""}</TableCell>
                                        <TableCell align="right">{row.ruta || row.pdf64 ? <IconButton onClick={() => abriRuta(row.ruta, row.pdf64)} color='primary'><PictureAsPdfIcon /></IconButton> : <IconButton color='error'><PictureAsPdfIcon /></IconButton>}</TableCell>
                                        {name.perfiles && name.perfiles.some(v => v.nombre === "rrhh") ? (
                                            <>
                                                <TableCell align="right">{row.no_aplica === "NA" ? <CheckIcon color='success' /> : ""}</TableCell>
                                                <TableCell align="right"><IconButton onClick={() => openModalDoc(row.id, index)} color='primary'><BorderColorIcon /></IconButton></TableCell>
                                            </>
                                        ) : (name.perfiles && name.perfiles.some(v => v.nombre === "administrador") ? (
                                            <>
                                                <TableCell align="right">{row.no_aplica === "NA" ? <CheckIcon color='success' /> : ""}</TableCell>
                                                <TableCell align="right">{row.categoria}</TableCell>
                                                <TableCell align="right"><IconButton onClick={() => openModalDoc(row.id, index)} color='primary'><BorderColorIcon /></IconButton></TableCell>
                                                <TableCell align="right"><IconButton onClick={() => eliminarGD(row.id)} color='primary'><DeleteIcon /></IconButton></TableCell>
                                            </>
                                        ) : ""
                                        )}

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CustomTabPanel>
                {/** CERTIFICACIONES */}
                <CustomTabPanel value={value} index={3}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Documento</TableCell>
                                    <TableCell align="right">Fecha Inicio</TableCell>
                                    <TableCell align="right">Fecha Termino</TableCell>
                                    <TableCell align="right">Status</TableCell>
                                    <TableCell align="right">Doc</TableCell>
                                    {name.perfiles && name.perfiles.some(v => v.nombre === "rrhh") ? (
                                        <>
                                            <TableCell align="right">Activar Alarma</TableCell>
                                            <TableCell align="right">Editar</TableCell>
                                        </>
                                    ) : (
                                        name.perfiles && name.perfiles.some(v => v.nombre === "administrador") ? (
                                            <>
                                                <TableCell align="right">Activar Alarma</TableCell>
                                                <TableCell align="right">Categoria</TableCell>
                                                <TableCell align="right">Editar</TableCell>
                                                <TableCell align="right">Eliminar</TableCell>
                                            </>
                                        ) : ""
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {documentos.filter(v => v.categoria === "CERTIFICACIONES").map((row, index) => (
                                    <TableRow
                                        key={row.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="right">{moment(row.valid_from).format("DD-MM-YYYY")}</TableCell>
                                        <TableCell align="right">{moment(row.valid_through).format("DD-MM-YYYY")}</TableCell>
                                        <TableCell align='right'>{semaforoFecha(row.valid_through) === "Verde" ? <CircleIcon sx={{ color: "green" }} /> : semaforoFecha(row.valid_through) == "Rojo" ? <CircleIcon sx={{ color: "red" }} /> : semaforoFecha(row.valid_through) == "Naranja" ? <CircleIcon sx={{ color: "orange" }} /> : semaforoFecha(row.valid_through) == "Amarillo" ? <CircleIcon sx={{ color: "yellow" }} /> : ""}</TableCell>
                                        <TableCell align="right">{row.ruta || row.pdf64 ? <IconButton onClick={() => abriRuta(row.ruta, row.pdf64)} color='primary'><PictureAsPdfIcon /></IconButton> : <IconButton color='error'><PictureAsPdfIcon /></IconButton>}</TableCell>
                                        {name.perfiles && name.perfiles.some(v => v.nombre === "rrhh") ? (
                                            <>
                                                <TableCell align="right">{row.no_aplica === "NA" ? <CheckIcon color='success' /> : ""}</TableCell>
                                                <TableCell align="right"><IconButton onClick={() => openModalDoc(row.id, index)} color='primary'><BorderColorIcon /></IconButton></TableCell>
                                            </>
                                        ) : (name.perfiles && name.perfiles.some(v => v.nombre === "administrador") ? (
                                            <>
                                                <TableCell align="right">{row.no_aplica === "NA" ? <CheckIcon color='success' /> : ""}</TableCell>
                                                <TableCell align="right">{row.categoria}</TableCell>
                                                <TableCell align="right"><IconButton onClick={() => openModalDoc(row.id, index)} color='primary'><BorderColorIcon /></IconButton></TableCell>
                                                <TableCell align="right"><IconButton onClick={() => eliminarGD(row.id)} color='primary'><DeleteIcon /></IconButton></TableCell>
                                            </>
                                        ) : ""
                                        )}

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CustomTabPanel>
                {/** OTROS */}
                <CustomTabPanel value={value} index={4}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Documento</TableCell>
                                    <TableCell align="right">Fecha Inicio</TableCell>
                                    <TableCell align="right">Fecha Termino</TableCell>
                                    <TableCell align="right">Status</TableCell>
                                    <TableCell align="right">Doc</TableCell>
                                    {name.perfiles && name.perfiles.some(v => v.nombre === "rrhh") ? (
                                        <>
                                            <TableCell align="right">Activar Alarma</TableCell>
                                            <TableCell align="right">Editar</TableCell>
                                        </>
                                    ) : (
                                        name.perfiles && name.perfiles.some(v => v.nombre === "administrador") ? (
                                            <>
                                                <TableCell align="right">Activar Alarma</TableCell>
                                                <TableCell align="right">Categoria</TableCell>
                                                <TableCell align="right">Editar</TableCell>
                                                <TableCell align="right">Eliminar</TableCell>
                                            </>
                                        ) : ""
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {documentos.filter(v => v.categoria === null || v.categoria === "OTROS").map((row, index) => (
                                    <TableRow
                                        key={row.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="right">{moment(row.valid_from).format("DD-MM-YYYY")}</TableCell>
                                        <TableCell align="right">{moment(row.valid_through).format("DD-MM-YYYY")}</TableCell>
                                        <TableCell align='right'>{semaforoFecha(row.valid_through) === "Verde" ? <CircleIcon sx={{ color: "green" }} /> : semaforoFecha(row.valid_through) == "Rojo" ? <CircleIcon sx={{ color: "red" }} /> : semaforoFecha(row.valid_through) == "Naranja" ? <CircleIcon sx={{ color: "orange" }} /> : semaforoFecha(row.valid_through) == "Amarillo" ? <CircleIcon sx={{ color: "yellow" }} /> : ""}</TableCell>
                                        <TableCell align="right">{row.ruta || row.pdf64 ? <IconButton onClick={() => abriRuta(row.ruta, row.pdf64)} color='primary'><PictureAsPdfIcon /></IconButton> : <IconButton color='error'><PictureAsPdfIcon /></IconButton>}</TableCell>
                                        {name.perfiles && name.perfiles.some(v => v.nombre === "rrhh") ? (
                                            <>
                                                <TableCell align="right">{row.no_aplica === "NA" ? <CheckIcon color='success' /> : ""}</TableCell>
                                                <TableCell align="right"><IconButton onClick={() => openModalDoc(row.id, index)} color='primary'><BorderColorIcon /></IconButton></TableCell>
                                            </>
                                        ) : (name.perfiles && name.perfiles.some(v => v.nombre === "administrador") ? (
                                            <>
                                                <TableCell align="right">{row.no_aplica === "NA" ? <CheckIcon color='success' /> : ""}</TableCell>
                                                <TableCell align="right">{row.categoria}</TableCell>
                                                <TableCell align="right"><IconButton onClick={() => openModalDoc(row.id, index)} color='primary'><BorderColorIcon /></IconButton></TableCell>
                                                <TableCell align="right"><IconButton onClick={() => eliminarGD(row.id)} color='primary'><DeleteIcon /></IconButton></TableCell>
                                            </>
                                        ) : ""
                                        )}

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CustomTabPanel>
                {/** HISORICOS */}
                <CustomTabPanel value={value} index={5}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Documento</TableCell>
                                    <TableCell align="right">Fecha Inicio</TableCell>
                                    <TableCell align="right">Fecha Termino</TableCell>
                                    <TableCell align="right">Status</TableCell>
                                    <TableCell align="right">Doc</TableCell>
                                    {name.perfiles && name.perfiles.some(v => v.nombre === "rrhh") ? (
                                        <>
                                            <TableCell align="right">Activar Alarma</TableCell>
                                            <TableCell align="right">Editar</TableCell>
                                        </>
                                    ) : (
                                        name.perfiles && name.perfiles.some(v => v.nombre === "administrador") ? (
                                            <>
                                                <TableCell align="right">Activar Alarma</TableCell>
                                                <TableCell align="right">Categoria</TableCell>
                                                <TableCell align="right">Editar</TableCell>
                                                <TableCell align="right">Eliminar</TableCell>
                                            </>
                                        ) : ""
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {documentos.filter(v => v.categoria === "HISTORICO").map((row, index) => (
                                    <TableRow
                                        key={row.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="right">{moment(row.valid_from).format("DD-MM-YYYY")}</TableCell>
                                        <TableCell align="right">{moment(row.valid_through).format("DD-MM-YYYY")}</TableCell>
                                        <TableCell align='right'>{semaforoFecha(row.valid_through) === "Verde" ? <CircleIcon sx={{ color: "green" }} /> : semaforoFecha(row.valid_through) == "Rojo" ? <CircleIcon sx={{ color: "red" }} /> : semaforoFecha(row.valid_through) == "Naranja" ? <CircleIcon sx={{ color: "orange" }} /> : semaforoFecha(row.valid_through) == "Amarillo" ? <CircleIcon sx={{ color: "yellow" }} /> : ""}</TableCell>
                                        <TableCell align="right">{row.ruta || row.pdf64 ? <IconButton onClick={() => abriRuta(row.ruta, row.pdf64)} color='primary'><PictureAsPdfIcon /></IconButton> : <IconButton color='error'><PictureAsPdfIcon /></IconButton>}</TableCell>
                                        {name.perfiles && name.perfiles.some(v => v.nombre === "rrhh") ? (
                                            <>
                                                <TableCell align="right">{row.no_aplica === "NA" ? <CheckIcon color='success' /> : ""}</TableCell>
                                                <TableCell align="right"><IconButton onClick={() => openModalDoc(row.id, index)} color='primary'><BorderColorIcon /></IconButton></TableCell>
                                            </>
                                        ) : (name.perfiles && name.perfiles.some(v => v.nombre === "administrador") ? (
                                            <>
                                                <TableCell align="right">{row.no_aplica === "NA" ? <CheckIcon color='success' /> : ""}</TableCell>
                                                <TableCell align="right">{row.categoria}</TableCell>
                                                <TableCell align="right"><IconButton onClick={() => openModalDoc(row.id, index)} color='primary'><BorderColorIcon /></IconButton></TableCell>
                                                <TableCell align="right"><IconButton onClick={() => eliminarGD(row.id)} color='primary'><DeleteIcon /></IconButton></TableCell>
                                            </>
                                        ) : ""
                                        )}

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CustomTabPanel>
            </Box>
            {/** Modal para el Ingreso de Documentos. */}
            <Modal
                open={modalIngreso}
                onClose={closeModalIngreso}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style_add_file}>
                    <AppBar>
                        <Typography id="modal-modal-title" variant="h6" component="h2" align="center" style={{ marginBottom: 10 }}>
                            Ingresar Documento
                        </Typography>
                    </AppBar>
                    <Box sx={{
                        margin: 3,
                    }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <FormControl fullWidth>
                                    {/* <InputLabel id="nombre">Nombre</InputLabel>
                                    <Select
                                        labelId="nombre"
                                        id="nombre"
                                        label="Nombre"
                                        onChange={(e) => handleGestion(e.target.value, 'name')}
                                        disabled={ingreso}
                                    >
                                        {maetroDocumentos.map(md => (
                                            <MenuItem key={md.nombre} value={md.nombre}>{md.nombre}</MenuItem>
                                        ))}
                                    </Select> */}
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={docsAuto}
                                        onChange={(event, newValue) => handleGestion(newValue.label, 'name')}
                                        renderInput={(params) => <TextField {...params} label="Nombre Documento" />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControl fullWidth>
                                    <InputLabel id="categoria">Categoria</InputLabel>
                                    <Select
                                        labelId="categoria"
                                        id="categoria"
                                        label="Categoria"
                                        onChange={(e) => handleGestion(e.target.value, 'categoria')}
                                        disabled={ingreso}
                                    >
                                        {categorias.map(md => (
                                            <MenuItem key={md.nombre} value={md.nombre}>{md.nombre}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                                    <DatePicker
                                        label="Inicio de Vigencia"
                                        value={gestion.valid_from ? gestion.valid_from : ""}
                                        onChange={(newValue) => handleGestion(newValue, 'valid_from')}
                                        renderInput={(params) => <TextField {...params} />}
                                        disabled={ingreso}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                                    <DatePicker
                                        label="Termino de Vigencia"
                                        value={gestion.valid_through ? gestion.valid_through : ""}
                                        onChange={(newValue) => handleGestion(newValue, 'valid_through')}
                                        renderInput={(params) => <TextField {...params} />}
                                        disabled={ingreso}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField label="Autor" onChange={(e) => handleGestion(e.target.value, 'author')} fullWidth value={authp.user.nombre} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControlLabel control={<Checkbox onChange={(e) => onChangeNa(e)} />} label="Activar Alarma" />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Button variant='contained' component='label' startIcon={<AttachFileIcon />} disabled={ingreso}>
                                    Cargar
                                    <input hidden type="file" onChange={(e) => handleGestion(e.target.files, 'archivo')} />
                                </Button>
                                {gestion?.archivo ? <p>{gestion.archivo[0].name}</p> : ""}
                            </Grid>
                        </Grid>
                    </Box>
                    <Box
                        sx={{
                            margin: 3,
                            alignItems: 'end',
                            justifyContent: 'right',
                            display: 'flex'
                        }}
                    >
                        <Button variant='contained' onClick={ingresarGD} disabled={ingreso}>Subir</Button>
                    </Box>
                </Box>

            </Modal>
        </Container>
    )
}

export default function ListarArchivosNonStack() {
    return (
        <SnackbarProvider maxSnack={3}>
            <ListarArchivos />
        </SnackbarProvider>
    );
}