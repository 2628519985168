import React, { useState } from 'react';

import Container from '@mui/material/Container';
import { Typography } from '@mui/material';

const PruebaPage = () => {

    return (
        <Container>
            <Typography variant='h2' style={{ justifyContent: 'center', textAlign: 'center' }}>Gestión Documental</Typography>
            <div style={{ textAlign: 'center' }}>
                <img src='/img/gd_principal.jpg' alt='Gestión Documental' />
            </div>
        </Container>
    )
}

export default PruebaPage;